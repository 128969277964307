<template>
  <BaseModal
    size="2xl"
    :background-close="false"
    :show="show"
    :show-close="false"
    title="Upgrade to Pro Plan"
    @close="$emit('close')"
  >
    <div class="prose">
      <p>
        Use our chat feature to execute ChatGPT conversations with your entire
        team right within PromptDrive.
      </p>
      <p class="text-yellow-700">
        You'll need to upgrade to the pro plan to use this feature.
      </p>
      <BaseButton class="no-underline" href="/settings/billing">
        Upgrade my plan
      </BaseButton>
      <BaseButton href="/" class="ml-3 no-underline" variant="white">
        Back to Prompts
      </BaseButton>
    </div>
  </BaseModal>
</template>

<script setup lang="ts">
import useApiFetch from '~/composables/useApiFetch'
import useCurrentUser from '~/composables/useCurrentUser'
import useAuthStore from '~/stores/useAuthStore'

defineProps({
  show: {
    type: Boolean,
    default: false,
  },
})

defineEmits(['close', 'update'])

const { organization } = useCurrentUser()
const form = ref({ ...organization.value })
const authStore = useAuthStore()
const canBeta = authStore.can('beta')
const submitting = ref(false)

if (!form.value.settings) {
  form.value.settings = {
    openai_api_key: null,
  }
}

const handleSubmit = async () => {
  submitting.value = true

  await useApiFetch('/organizations/current', {
    method: 'PUT',
    body: {
      settings: form.value.settings || {},
    },
  })

  window.location.reload()
}
</script>
