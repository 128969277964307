<template>
  <!-- Mobile sidebar -->
  <TransitionRoot as="template" :show="sidebarOpen">
    <Dialog as="div" class="relative z-30 md:hidden" @close="$emit('close')">
      <TransitionChild
        as="template"
        enter="transition-opacity ease-linear duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="transition-opacity ease-linear duration-300"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-900/80" />
      </TransitionChild>

      <div class="fixed inset-0 flex">
        <TransitionChild
          as="template"
          enter="transition ease-in-out duration-300 transform"
          enter-from="-translate-x-full"
          enter-to="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leave-from="translate-x-0"
          leave-to="-translate-x-full"
        >
          <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
            <TransitionChild
              as="template"
              enter="ease-in-out duration-300"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="ease-in-out duration-300"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <div
                class="absolute left-full top-0 flex w-16 justify-center pt-5"
              >
                <button
                  type="button"
                  class="-m-2.5 p-2.5"
                  @click="$emit('close')"
                >
                  <span class="sr-only">Close sidebar</span>
                  <BaseIcon
                    name="heroicons:x-mark"
                    class="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </TransitionChild>

            <!-- Sidebar component, swap this element with another sidebar if you like -->
            <div
              class="flex grow flex-col gap-y-5 overflow-y-auto bg-white pb-2"
            >
              <div class="flex h-16 shrink-0 items-center px-5">
                <SiteLogoText class="h-6" />
              </div>
              <nav class="flex flex-1 flex-col">
                <ul role="list" class="flex flex-1 flex-col gap-y-7">
                  <!-- NAV - NOT CURRENTLY USED -->
                  <li>
                    <ul role="list" class="space-y-1">
                      <li v-for="item in navigation" :key="item.name">
                        <BaseLink
                          :href="item.href"
                          :class="[
                            route.fullPath.includes(item.href)
                              ? 'bg-gray-50 text-indigo-600'
                              : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                            'group flex gap-x-3 rounded-md py-1 px-5 text-sm leading-6 font-semibold',
                          ]"
                          @click="$emit('close')"
                        >
                          <BaseIcon
                            :name="item.icon"
                            :class="[
                              item.current
                                ? 'text-indigo-600'
                                : 'text-gray-400 group-hover:text-indigo-600',
                              'h-6 w-6 shrink-0',
                            ]"
                            aria-hidden="true"
                          />
                          {{ item.name }}
                        </BaseLink>
                      </li>
                    </ul>
                  </li>
                  <!-- END:NAV -->
                  <li>
                    <SidebarFolders
                      :initial-values="{ folder_type: 'chat' }"
                      :selected="parseInt($route.params.id)"
                    />
                  </li>
                  <li v-if="false">
                    <SidebarTags :selected="parseInt($route.params.id)" />
                  </li>
                </ul>
              </nav>
            </div>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
  <!-- END: Mobile Sidebar -->

  <!-- Static sidebar for desktop -->
  <div class="hidden md:flex md:flex-col bg-gray-200 w-52 flex-none">
    <!-- Sidebar component, swap this element with another sidebar if you like -->
    <div
      class="flex grow flex-col overflow-y-auto border-r border-gray-200 bg-gray-50"
    >
      <div class="flex h-16 px-5 shrink-0 items-center">
        <div class="flex-1">
          <div class="flex items-center text-lg leading-5">Chats</div>
        </div>
      </div>
      <nav class="flex flex-1 flex-col px-3">
        <ul role="list" class="flex flex-1 flex-col gap-y-7">
          <!-- MENU - NOT CURRENTLY USED -->
          <li>
            <ul role="list" class="space-y-1">
              <li v-for="item in navigation" :key="item.name">
                <BaseLink
                  :href="item.href"
                  :class="[
                    route.fullPath.includes(item.href)
                      ? 'bg-gray-100 text-indigo-700'
                      : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                    'group flex relative gap-x-3 py-1 px-2.5 rounded text-sm leading-6 font-semibold items-center cursor-pointer',
                  ]"
                >
                  <BaseIcon
                    :name="item.icon"
                    :class="[
                      item.current
                        ? 'text-indigo-600'
                        : 'text-gray-400 group-hover:text-indigo-600',
                      'h-5 w-5 shrink-0',
                    ]"
                    aria-hidden="true"
                  />
                  {{ item.name }}
                </BaseLink>
              </li>
            </ul>
          </li>
          <!-- END:MENU -->
          <li>
            <SidebarFolders
              :selected="parseInt($route.params.id)"
              :initial-values="{ folder_type: 'chat' }"
            />
          </li>
          <li v-if="false">
            <SidebarTags :selected="parseInt($route.params.id)" />
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script setup>
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import useApiFetch from '~/composables/useApiFetch'

defineProps({
  sidebarOpen: {
    type: Boolean,
    default: false,
  },
})

defineEmits(['close'])

const showFolderCreate = ref(false)
const route = useRoute()
const navigation = [
  {
    name: 'All',
    href: '/chats/all',
    icon: 'heroicons:home',
    current: true,
  },
  {
    name: 'Unsorted',
    href: '/chats/unsorted',
    icon: 'heroicons:inbox',
    current: false,
  },
]
</script>
