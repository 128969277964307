<template>
  <template v-if="authenticated">
    <NuxtLoadingIndicator :height="4" color="#6366f1" />
    <div class="flex flex-row h-screen">
      <AppSidebar />
      <ChatSidebar
        :sidebar-open="sidebarOpen"
        @close="sidebarOpen = false"
        v-if="showSidebar"
      />
      <div class="flex grow flex-col">
        <div class="flex flex-none bg-red-200">
          <TheHeader
            :term="route.query.term as string || ''"
            type="chat"
            @toggle-sidebar="sidebarOpen = !sidebarOpen"
            @search="handleSearch"
          />
        </div>
        <template v-if="!pending">
          <slot />
        </template>
      </div>
    </div>
  </template>
  <BaseToast />
  <FolderDeleteModal
    :show="showFolderDeleteModal"
    :folder="folder"
    @close="showFolderDeleteModal = false"
    @delete="handleFolderDelete"
  />
  <FolderFormModal
    :show="showFolderModal"
    :initial-values="folder"
    @close="showFolderModal = false"
    @update="showFolderModal = false"
  />
  <FolderShareModal
    :initial-values="folder"
    :show="showFolderShareModal"
    @close="showFolderShareModal = false"
  />
  <MissingApiKeyModal
    :show="showMissingApiKeyModal && !showUpgradeRequiredModal"
    @close="showMissingApiKeyModal = false"
  />
  <UpgradeRequiredModal :show="!pending && showUpgradeRequiredModal" />
</template>

<script setup lang="ts">
import useAuthStore from '~/stores/useAuthStore'
import useChatStore from '~/stores/useChatStore'
import useFolderStore from '~/stores/useFolderStore'
import usePromptStore from '~/stores/usePromptStore'
import useEventBus, { EVENTS } from '~/composables/useEventBus'

import { storeToRefs } from 'pinia'

const authStore = useAuthStore()
const chatStore = useChatStore()
const promptStore = usePromptStore()

const { authenticated, user, organization } = storeToRefs(authStore)

const pending = ref(true)
const sidebarOpen = ref(false)
const route = useRoute()
const router = useRouter()
const showSidebar = ref(true)
const showMissingApiKeyModal = ref(false)
const showUpgradeRequiredModal = computed(() => {
  return authStore.can('chat') !== true
})

const handleSearch = ({ term }: { term: string }) => {
  router.push(`/prompts/folders/search?term=${encodeURIComponent(term || '')}`)
}

useEventBus({ name: EVENTS.CREATE_CHAT }).on(({ chat }) => {
  chatStore.add({ chat })
})

useEventBus({ name: EVENTS.TOGGLE_SIDEBAR }).on(() => {
  showSidebar.value = !showSidebar.value
})

useEventBus({ name: EVENTS.DELETE_CHAT }).on(({ chat }) => {
  chatStore.remove({ chatId: chat.id })
})

// ------------------------------------------------------
// FOLDERS
// ------------------------------------------------------

const folder = ref({})
const folderStore = useFolderStore()
const createFolderBus = useEventBus({ name: EVENTS.CREATE_FOLDER })
const deleteFolderBus = useEventBus({ name: EVENTS.DELETE_FOLDER })
const editFolderBus = useEventBus({ name: EVENTS.EDIT_FOLDER })
const { folders } = storeToRefs(folderStore)
const shareFolderBus = useEventBus({ name: EVENTS.SHARE_FOLDER })
const showFolderDeleteModal = ref(false)
const showFolderModal = ref(false)
const showFolderShareModal = ref(false)

const fetchFolders = async () => {
  await folderStore.fetch({ query: 'folder_type=prompt' })
}

const handleFolderDelete = () => {
  promptStore.fetchPromptTags()

  if (folders.value.data.length === 0) {
    router.push('/chats/all')
  } else {
    router.push(`/chats/${folders.value.data[0].id}`)
  }
}

createFolderBus.on(({ folder }) => {
  router.push(`/chats/${folder.id}`)
})

shareFolderBus.on((event) => {
  showFolderShareModal.value = true
  folder.value = event.folder
})

deleteFolderBus.on((event) => {
  showFolderDeleteModal.value = true
  folder.value = event.folder
})

editFolderBus.on((event) => {
  showFolderModal.value = true
  sidebarOpen.value = false
  folder.value = event.folder
})

onMounted(async () => {
  await authStore.fetch()
  await folderStore.fetch({ query: 'folder_type=chat' })
  await chatStore.fetchTags()
  await chatStore.fetch()

  // don't block for these
  chatStore.fetchOpenAiModels()
  promptStore.fetchPrompts()

  showMissingApiKeyModal.value = !organization.value?.settings?.openai_api_key
  pending.value = false
})
</script>
