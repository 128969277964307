<template>
  <BaseModal
    size="2xl"
    :background-close="false"
    :show="show"
    :show-close="false"
    title="OpenAI API Key Required"
    @close="$emit('close')"
  >
    <div class="prose">
      <p>
        This feature requires an OpenAI API Key.
        <a
          class="underline text-indigo-600"
          href="https://platform.openai.com/account/api-keys"
          target="_BLANK"
        >
          Create your key here
        </a>
      </p>
      <FormKit
        v-model="form"
        type="form"
        :actions="false"
        :incomplete-message="false"
        @submit="handleSubmit"
      >
        <FormKit type="group" v-model="form.settings">
          <FormKit
            type="text"
            name="openai_api_key"
            label="OpenAI API Key"
            placeholder="sk_xxxxxxxxxxxxxxxxxxxxxxxx"
            validation="required"
          />
        </FormKit>
        <BaseButton class="mt-3" :loading="submitting">Save</BaseButton>
      </FormKit>
      <p class="text-gray-500 text-sm">
        You can change this key later in the
        <BaseLink href="/settings" class="underline text-indigo-600">
          settings
        </BaseLink>
        section
      </p>
    </div>
  </BaseModal>
</template>

<script setup lang="ts">
import useApiFetch from '~/composables/useApiFetch'
import useCurrentUser from '~/composables/useCurrentUser'
import useAuthStore from '~/stores/useAuthStore'

defineProps({
  show: {
    type: Boolean,
    default: false,
  },
})

defineEmits(['close', 'update'])

const { organization } = useCurrentUser()
const form = ref({ ...organization.value })
const authStore = useAuthStore()
const canBeta = authStore.can('beta')
const submitting = ref(false)

if (!form.value.settings) {
  form.value.settings = {
    openai_api_key: null,
  }
}

const handleSubmit = async () => {
  submitting.value = true

  await useApiFetch('/organizations/current', {
    method: 'PUT',
    body: {
      settings: form.value.settings || {},
    },
  })

  window.location.reload()
}
</script>
